<template>
	<div class="tableBase">
		<el-row style="margin: 5px 0 15px 0; width: 100%" v-if="queryItem || custom || otherBtns">
			<template v-if="queryList && queryList.length">
				<el-col v-for="item in queryList" :key="item.key" :span="item.span || 6">
					<div class="inputBox">
						<div v-if="item.label" class="labelText" :style="`width: ${item.labelWidth || 100}px`">
							{{ item.label || '' }}
						</div>
						<el-input
							v-if="item.type === 'input'"
							v-model="params[item.key]"
							size="small"
							placeholder="请输入"
							:clearable="item.clearable === false ? false : true"
							@input="(item?.change && ((e) => item.change(e))) || (() => {})"
						></el-input>
						<el-select
							v-else-if="item.type === 'select'"
							v-model="params[item.key]"
							size="small"
							:clearable="item.clearable === false ? false : true"
							filterable
							:placeholder="item.placeholder || '请选择'"
							@change="item.change && item.change(params, queryItem)"
							:style="`width: 100%;${item.inputStyle || ''}`"
						>
							<el-option v-for="item in getOpts(item)" :key="item.dictId" :value="item.dictId" :label="item.dictName"></el-option>
						</el-select>
						<el-select
							v-else-if="item.type === 'selectSearch'"
							:value="params[item.showKey] || params[item.key]"
							@change="(e) => selectValueChange(e, item)"
							size="small"
							:multiple="item.multiple || false"
							filterable
							remote
							reserve-keyword
							placeholder="请输入关键词"
							:remote-method="(e) => remoteMethod(e, item)"
							:loading="selectLoading"
							clearable
							style="width: 100%"
							:disabled="item.disabled"
						>
							<el-option v-for="item in getOpts(item)" :key="item.dictId" :value="item.dictId" :label="item.dictName"> </el-option>
						</el-select>
						<el-popover placement="bottom" trigger="click" v-else-if="item.type === 'tree'" style="width: 100%">
							<el-tree
								:ref="'tree' + item.key"
								:data="item?.options?.length ? item.options : treeData || opts[item.key]"
								:node-key="item.nodeKey"
								:props="item.props"
								:show-checkbox="item.multiple"
								default-expand-all
								@check="(_, checkKey) => treeCheck(item, { ..._, ...checkKey })"
								@node-click="(d) => treeNodeClick(item, d)"
							></el-tree>
							<el-input
								slot="reference"
								v-model="params[item.showKey || item.key]"
								size="small"
								placeholder="请选择"
								:clearable="item.clearable === false ? false : true"
								@clear="(e) => clearTreeInput(e, item)"
								style="width: 100%"
								@input="(e) => treeChange(e, item)"
							></el-input>
						</el-popover>
						<el-date-picker
							v-else-if="item.type === 'date'"
							v-model="params[item.key]"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							size="small"
							placeholder="选择时间"
							style="width: 100%"
							:clearable="item.clearable === false ? false : true"
						></el-date-picker>
						<!-- 年份 -->
						<el-date-picker
							v-else-if="item.type === 'year'"
							v-model="params[item.key]"
							value-format="yyyy"
							type="year"
							format="yyyy"
							size="small"
							placeholder="选择时间"
							:clearable="item.clearable === false ? false : true"
						></el-date-picker>
						<!-- 日期范围 -->
						<el-date-picker
							v-else-if="item.type === 'daterange'"
							v-model="params[item.key]"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							type="daterange"
							size="small"
							range-separator="-"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							:clearable="item.clearable === false ? false : true"
						></el-date-picker>
						<!-- 年度范围选择 -->
						<YearRange
							v-else-if="item.type === 'yearrange'"
							:value="params[item.key]"
							@input="(e) => (params[item.key] = e)"
							sp="-"
							:clearable="item.clearable === false ? false : true"
						></YearRange>
						<!-- 级联 -->
						<el-cascader
							v-else-if="item.type === 'cascader'"
							ref="cascaderLinkage"
							:show-all-levels="item.showAllLevels !== false"
							size="small"
							v-model="params[item.key]"
							:options="getOpts(item)"
							:props="item.props"
							clearable
							style="width: 100%"
							:placeholder="item.placeholder || '请选择'"
						></el-cascader>
					</div>
				</el-col>
				<div class="query">
					<el-button type="primary" size="small" icon="el-icon-search" @click="handleSearch()">查询</el-button>
					<el-button type="primary" size="small" icon="el-icon-refresh" @click="handleReset()" v-if="showReset">重置</el-button>
					<el-button
						type="primary"
						size="small"
						:icon="`${isOpen ? 'el-icon-arrow-up' : 'el-icon-arrow-down'}`"
						@click="isOpen = !isOpen"
						v-if="defaultShowQueryItemNum"
						>{{ isOpen ? '收起' : '展开' }}</el-button
					>
				</div>
			</template>

			<div style="display: inline-flex; float: right" v-if="otherBtns || custom">
				<template v-if="otherBtns">
					<div v-for="it in otherBtns" :key="it.label" :style="`display: inline-block; height: 42px; margin-top:10px;`">
						<Render :style="`margin: 0 10px`" v-if="it.render" :render="() => it.render({ tableData })" />
						<el-button
							v-else
							:style="`margin: 0 10px`"
							type="primary"
							size="small"
							:icon="it.icon"
							@click="(it.click && it.click(tableData, params)) || null"
							>{{ it.label }}
						</el-button>
					</div>
				</template>

				<div class="operation" v-if="custom">
					<el-popover placement="bottom-end" trigger="click">
						<el-tree
							:data="columns"
							show-checkbox
							node-key="key"
							:default-checked-keys="checkedKeys"
							:props="defaultProps"
							:default-expand-all="true"
							:check-on-click-node="true"
							:expand-on-click-node="false"
							@check="customtreeCheck"
						>
						</el-tree>
						<el-button
							class="vxe-button type--button is--circle"
							slot="reference"
							title="筛选显示列"
							style="padding: 8px 8px 7px 8px; border-radius: 50%"
						>
							<i class="vxe-button--icon vxe-icon-custom-column"></i>
						</el-button>
					</el-popover>
				</div>
			</div>
		</el-row>
		<div style="width: 100%">
			<slot name="title"></slot>
			<el-table
				:data="tableData"
				slot="empty"
				v-loading="loading"
				style="width: 100%"
				class="tableBackground"
				:show-header="showHeader !== false"
				:span-method="spanMethod"
				:border="showBorder !== false"
				:summary-method="summaryMethod"
				:show-summary="showSummary"
				:default-expand-all="defaultExpandAll"
				:row-key="rowKey || 'id'"
				@cell-mouse-enter="(...e) => mouseEvent(e, 1)"
				@cell-mouse-leave="(...e) => mouseEvent(e, 2)"
				@row-click="rowClick"
				@row-contextmenu="rowContextmenu"
			>
				<template slot="empty">
					<Empty />
				</template>
				<!-- <el-table-column type="selection" width="55" align="center"> </el-table-column> -->
				<el-table-column type="index" label="序号" :width="80" align="center" v-if="showOrder" :index="indexMethod"></el-table-column>
				<el-table-column
					v-for="(cols, index) in showColumns"
					:key="index"
					:prop="cols.key"
					:label="cols.label"
					:align="cols.align || 'center'"
					:width="cols.width"
					:show-overflow-tooltip="cols.showOverflow === false ? cols.showOverflow : true"
					class="testesteste"
					:fixed="cols.fixed"
				>
					<template v-if="cols.children?.length">
						<el-table-column
							v-for="(item, indx) in cols.children"
							:key="indx"
							:prop="item.key"
							:label="item.label"
							:align="item.align || 'center'"
							:width="item.width"
							:show-overflow-tooltip="item.showOverflow !== false"
							:fixed="item.fixed"
						>
							<template v-if="item.children?.length">
								<el-table-column
									v-for="(ite, _index) in item.children"
									:key="_index"
									:prop="ite.key"
									:label="ite.label"
									:align="ite.align || 'center'"
									:width="ite.width"
									:show-overflow-tooltip="ite.showOverflow !== false"
								>
									<template v-if="ite.formatter" v-slot="{ row }">
										<span>{{ ite.formatter(row[item.key], row, getOptsById) || '' }}</span>
									</template>
									<template v-else-if="ite.render" v-slot="{ row, $index }">
										<Render :render="() => ite.render(row, $index, ite)" />
									</template>
								</el-table-column>
							</template>
							<template v-if="item.formatter" v-slot="{ row }">
								<span>{{ item.formatter(row[item.key], row) || '' }}</span>
							</template>
							<template v-else-if="item.render" v-slot="{ row, $index }">
								<Render :render="() => item.render(row, $index, item)" />
							</template>
							<template v-else-if="item.wrap" v-slot="{ row }">
								<div style="white-space: pre-line; text-align: left">{{ row[item.key] }}</div>
							</template>
						</el-table-column>
					</template>
					<template v-slot="{ row }" v-if="cols.formatter">
						<span>{{ cols.formatter(row[cols.key], row, getOptsById) || '' }}</span>
					</template>
					<template v-else-if="cols.render" v-slot="{ row, $index }">
						<Render :render="() => cols.render(row, $index, cols)" />
					</template>
					<template v-else-if="cols.wrap" v-slot="{ row }">
						<div style="white-space: pre-line; text-align: left">{{ row[cols.key] }}</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<div style="width: 100%; height: 60px" v-if="showPagination">
			<el-pagination
				class="fr"
				style="margin: 20px 0"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pagination.pageNo"
				:page-sizes="[20, 50, 100, 200, 300, 400]"
				:page-size="pagination.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="pagination.total"
			></el-pagination>
		</div>
	</div>
</template>

<script>
import Render from './render';
import Empty from '../components-empty/index.vue';
import { cloneDeep } from 'loadsh';
import { setStore, getStore, transfromTreeData } from '@/utils/util';
import YearRange from '../components-year-range/index.vue';

export default {
	name: 'tableCommonList',
	components: { Render, Empty, YearRange },

	props: {
		showHeader: { type: Boolean, default: () => true, required: false },
		showBorder: { type: Boolean, default: () => true, required: false },
		showOrder: { type: Boolean, default: () => false, required: false },
		columns: { type: Array, default: () => [], required: true },
		dataList: { type: Array, required: false },
		queryItem: { type: Array, required: false },
		otherBtns: { type: Array, required: false },
		queryValues: { type: Object, required: false },
		showPagination: { type: Boolean, default: true, required: false },
		spanMethod: { type: Function, required: false },
		custom: { type: [Boolean, Object], required: false },
		activeTab: { type: String, required: false },
		rowKey: { type: String, required: false },
		showSummary: { type: Boolean, required: false },
		defaultExpandAll: { type: Boolean, required: false },
		summaryMethod: { type: Function, required: false },
		fetchAllData: { type: Boolean, required: false },
		showReset: { type: Boolean, default: false, required: false },
		defaultShowQueryItemNum: { type: Number, required: false }
	},
	computed: {
		queryList() {
			if (this.defaultShowQueryItemNum && !this.isOpen) {
				return this.queryItem.slice(0, this.defaultShowQueryItemNum);
			}
			return this.queryItem;
		},
		getOptsById() {
			return (id) => this.$store.state.app?.dict?.filter((item) => item.groupId === id) || [];
		},
		// 默认全选
		checkedKeys() {
			if (this.custom?.local) {
				let local = getStore(`${this.$route.name}_${this.activeTab}_custom`);

				if (local) {
					local = JSON.parse(local);
					return local;
				}
			}
			return this.unfoldListCol(this.columns);
		},
		treeListData() {
			return ({ key, props }) => transfromTreeData(this.opts[key], props.children);
		}
	},
	data() {
		return {
			// 分页参数
			pagination: {
				pageNo: 1,
				pageSize: 20,
				total: 0
			},
			// 搜索条件对象
			params: {
				pageNo: 1,
				pageSize: 20
			},
			tableData: [],
			loading: false,
			opts: {},
			defaultProps: {
				children: 'children',
				label: 'label'
				// disabled: (data) => ['level', 'hierarchy'].includes(data.value)
			},
			showColumns: [...this.columns],
			treeData: null,
			selectLoading: false,
			tableAllData: [],
			isOpen: false
		};
	},
	watch: {
		dataList: {
			deep: true,
			handler(val) {
				const { pageSize, pageNo } = this.pagination;

				this.tableData = val.slice((pageNo - 1) * pageSize, (pageNo - 1) * pageSize + pageSize);
				this.pagination.total = val.length;
			}
		},
		queryValues: {
			deep: true,
			handler(val) {
				if (val) {
					this.params = { ...this.params, ...this.queryValues };
				}
			}
		},
		// opts(val) {
		// 	const { state, commit } = this.$store;
		// 	commit('SET_OPTIONS', { ...state.setData.options, ...val });
		// },
		opts: {
			deep: true,
			handler(val) {
				const { state, commit } = this.$store;

				commit('SET_OPTIONS', { ...state.setData.options, ...val });
			}
		},
		columns(val) {
			let arr = [...val];

			if (this.custom?.local) {
				let local = getStore(`${this.$route.name}_${this.activeTab}_custom`);

				if (local) {
					local = JSON.parse(local);
					arr = this.filterCols(arr, local);
				}
			}
			this.showColumns = arr;
		}
	},
	created() {},
	mounted() {
		if (this.queryValues) {
			this.params = { ...this.params, ...this.queryValues };
		}
		this.$nextTick(() => {
			if (this.dataList) {
				const { pageSize } = this.pagination;

				this.tableData = this.dataList.slice(0, pageSize);
				this.pagination.total = this.dataList.length;
			} else {
				this.handleSearch();
			}
			if (this.custom?.local) {
				let local = getStore(`${this.$route.name}_${this.activeTab}_custom`);

				if (local) {
					local = JSON.parse(local);
					this.showColumns = this.filterCols(this.showColumns, local);
				}
			}
		});

		if (this.queryItem) {
			const fetchList = [];

			this.queryItem
				.filter((val) => val.async)
				.forEach((item) => {
					fetchList.push(item);
				});
			Promise.all(
				fetchList.map((it) => {
					if (it.onlyOnceRequest) {
						const opts = this.$store.state.setData.options;

						if (opts[it.key]?.length) {
							return Promise.resolve(opts[it.key]);
						}
					}
					return it.async();
				})
			).then((res) => {
				if (res && res.length) {
					fetchList.forEach(({ key }, inx) => {
						this.$set(this.opts, [key], res[inx] || []);
					});
				}
			});
		}
	},
	methods: {
		mouseEvent(e, type) {
			switch (type) {
				case 1:
					this.$emit('cellMouseEnter', e);
					break;
				case 2:
					this.$emit('cellMouseLeave', e);
					break;

				default:
					break;
			}
		},
		filterCols(cols, list) {
			let colums = cols;

			colums = colums
				.filter((item) => list.includes(item.key))
				.map((item) => {
					let it = { ...item };

					if (item.children && item.children.length) {
						it = { ...item, children: this.filterCols(item.children, list) };
					}
					return it;
				});
			return colums;
		},
		// 排序
		indexMethod(index) {
			return index + (this.pagination.pageNo - 1) * this.pagination.pageSize + 1;
		},

		handleSearch() {
			if (this.dataList) {
				this.pagination.pageNo = 1;
				if (this.queryItem?.length) {
					const searchKeys = this.queryItem.map(({ key }) => key);
					const { pageNo, pageSize } = this.pagination;

					let data = [...this.dataList];

					searchKeys.forEach((key) => {
						if (this.params[key]) {
							data = data.filter((val) => val[key].includes(this.params[key]));
						}
					});
					this.pagination.total = data.length;
					return (this.tableData = data.slice((pageNo - 1) * pageSize, (pageNo - 1) * pageSize + pageSize));
				}
			}
			this.loading = true;

			const params = {
				request: { ...this.params }
			};

			this.$emit('request', params, (ajaxFn) => {
				ajaxFn
					.then((result) => {
						if (this.fetchAllData) {
							const { pageSize } = this.pagination;

							this.tableAllData = result;
							this.pagination.total = result.length;
							this.pagination.pageNo = 1;
							this.tableData = result.slice(0, pageSize);
						} else {
							this.tableData = result.list;
							this.pagination.total = result.totalNum;
						}
						this.loading = false;
					})
					.catch(() => {
						this.loading = false;
					});
			});
		},
		handleReset() {
			this.params = { pageNo: 1, pageSize: 20 };
			this.handleSearch();
		},
		handleSizeChange(val) {
			this.pagination.pageSize = val;
			this.params.pageSize = val;
			if (this.fetchAllData) {
				const { pageSize } = this.pagination;

				this.pagination.pageNo = 1;
				this.tableData = this.tableAllData.slice(0, pageSize);
				return;
			}
			if (this.dataList) {
				const { pageNo, pageSize } = this.pagination;

				return (this.tableData = this.dataList.slice((pageNo - 1) * pageSize, (pageNo - 1) * pageSize + pageSize));
			}
			this.handleSearch();
		},
		handleCurrentChange(val) {
			this.pagination.pageNo = val;
			this.params.pageNo = val;
			if (this.fetchAllData) {
				const { pageNo, pageSize } = this.pagination;

				this.tableData = this.tableAllData.slice((pageNo - 1) * pageSize, (pageNo - 1) * pageSize + pageSize);
				return;
			}
			if (this.dataList) {
				const { pageNo, pageSize } = this.pagination;

				return (this.tableData = this.dataList.slice((pageNo - 1) * pageSize, (pageNo - 1) * pageSize + pageSize));
			}
			this.handleSearch();
		},
		// 下拉选项
		getOpts({ groupId, options, key, formatOpts, filterOpts }) {
			if (options) {
				if (typeof options === 'string') {
					return this.opts[options];
				}
				if (typeof options === 'function') {
					return options(this.opts) || [];
				}
				if (typeof options === 'object') {
					return options || [];
				}
			}
			if (this.opts[key]) {
				const opt = this.$store.state.setData.options?.[key];

				return opt || this.opts[key];
			}
			if (groupId) {
				let opts = this.getOptsById(groupId) || [];

				if (formatOpts && typeof formatOpts === 'function') {
					opts = opts.map((it) => formatOpts(it));
				}
				if (filterOpts && typeof filterOpts === 'function') {
					opts = filterOpts(opts, this.params);
				}
				return opts;
			}
			return [];
		},
		// showKey逻辑
		selectValueChange(e, { key }) {
			this.$set(this.params, key, e);
		},
		remoteMethod(query, { async, key }) {
			this.selectLoading = true;
			async(query).then((res) => {
				this.selectLoading = false;
				// this.opts[key] = res;
				this.$set(this.opts, key, res);
			});
		},
		// 点击行
		rowClick(e) {
			this.$emit('rowClick', e);
		},
		// 右键点击行
		rowContextmenu(row, column, event) {
			this.$emit('rowContextmenu', row, column, event);
		},
		// tree 勾选
		treeCheck(item, { checkedNodes, checkedKeys }) {
			this.$set(this.params, item.showKey, checkedNodes.map(({ orgName }) => orgName).join(','));
			this.$set(this.params, item.key, checkedKeys.join(','));
		},
		// tree  node点击
		treeNodeClick(item, data) {
			if (item.multiple) {
				return;
			}
			if (item.showKey) {
				this.$set(this.params, item.showKey, data[item.nodeName]);
			}
			this.$set(this.params, item.key, data[item.nodeKey]);
			this.$nextTick(() => {
				document.querySelector('div').click();
			});
		},
		// 清除
		clearTreeInput(e, item) {
			this.$set(this.params, item.showKey, undefined);
			this.$set(this.params, item.key, undefined);
			this.$refs[`tree${item.key}`][0].setCheckedKeys([]);
		},
		// treechange
		treeChange(e, { key, props, filter }) {
			if (!filter) {
				return;
			}
			if (e) {
				// 过滤
				this.treeData = this.treeListData({ key, props }).filter((it) => it[props.label].includes(e));
			} else {
				this.treeData = null;
			}
		},
		// 嵌套数据铺平
		unfoldListCol(cols) {
			if (cols && cols.length === 0) {
				return [];
			}
			let arr = [];

			cols.forEach((item) => {
				const p = item.children ? [item.key, ...this.unfoldListCol(item.children)] : item.key;

				if (p) {
					arr = arr.concat(p);
				}
			});
			return arr;
		},
		// 自定义树勾选
		customtreeCheck(checkedNodes, { checkedKeys, halfCheckedKeys }) {
			const cols = cloneDeep(this.columns);

			this.showColumns = this.filterCols(cols, [...checkedKeys, ...halfCheckedKeys]);
			if (this.custom?.local) {
				setStore(`${this.$route.name}_${this.activeTab}_custom`, JSON.stringify([...checkedKeys, ...halfCheckedKeys]));
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	display: flex;
	flex-wrap: wrap;
}

.toolBox {
	width: 100%;
	margin-bottom: 10px;

	div {
		float: right;
	}
}

.tableBase {
	.operation {
		display: inline-flex;
		float: right;
		margin-top: 10px;
	}

	.inputBox {
		margin: 0;
		height: 52px;

		.labelText {
			text-align: right;
			margin-right: 10px;
			font-size: 14px;
			color: #484848;
		}
	}

	.query {
		display: inline-flex;
		align-items: center;
		height: 52px;
		margin-left: 20px;
	}
}
</style>
<style lang="scss">
.textarea {
	width: 100%;

	.el-form-item__content {
		width: 80%;
	}
}

.el-table .cell.el-tooltip {
	min-height: 20px;
}

.tableBase {
	.el-table__body {
		height: 100%;
	}
}
</style>
